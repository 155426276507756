<template>
  <span
    :class="`inline-flex items-center px-2.5 py-0.5 rounded-full uppercase text-xs font-medium ${badgeColor}`"
  >
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "MBadge",
  props: {
    color: {
      type: String,
      default: "yellow",
    },
  },
  computed: {
    badgeColor() {
      return `bg-${this.color}-100 text-${this.color}-800`;
    },
  },
};
</script>
