<template>
  <div>
    <SectionLoading v-if="loading" text="Release data loading..." />

    <div v-else>
      <section v-if="releases.length == 0" class="mx-auto my-8 md:w-2/5">
        <h1 class="text-4xl font-bold text-black">
          Release Your First Track with Melior Today!
        </h1>
        <p class="my-3">Track release requirement</p>

        <div class="flex items-center space-x-3 mb-4">
          <ImageIcon size="30" class="text-primary" />
          <p>Square cover art with a size at least 3000 x 3000 pixels</p>
        </div>

        <div class="flex items-center space-x-3">
          <MusicIcon size="30" class="text-primary" />
          <p>Audio files with 44.1 kHz and 16-bit stereo</p>
        </div>

        <div class="my-3">
          <router-link to="/new-release" class="text-center">
            <PlusCircleIcon size="100" class="text-primary mx-auto" />
          </router-link>
        </div>
      </section>

      <section v-else>
        <UpgradePlanWidget
          v-if="user.subscription && user.subscription.plan.name.toLowerCase() === 'melior start' || user.subscription && user.subscription.plan.name.toLowerCase() === ''" />

        <div class="card siteCard">
          <div class="p-3">
            <div class="grid grid-cols-2 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
              <div class="flex items-center space-x-3">
                <MusicIcon size="30" class="text-primary" />
                <div>
                  <h3 class="text-2xl font-bold text-primary">
                    {{ statistics.total_streams | number("0,0") }}
                  </h3>
                  <p class="text-sm">Total Streams</p>
                </div>
              </div>

              <div class="flex items-center space-x-3">
                <DownloadIcon size="30" class="text-primary" />
                <div>
                  <h3 class="text-2xl font-bold text-primary">
                    {{ statistics.total_downloads | number("0,0") }}
                  </h3>
                  <p class="text-sm">Downloads</p>
                </div>
              </div>

              <div class="flex items-center space-x-3">
                <CreditCardIcon size="30" class="text-primary" />
                <div>
                  <h3 class="text-2xl font-bold text-primary">
                    {{
                      $formatCurrency(
                        statistics.user_earnings_after_deduction,
                        statistics.user_earning_currency
                      )
                    }}
                  </h3>
                  <p class="text-sm">Total earnings</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card siteCard" style="margin-bottom: 3rem">
          <div class="p-3">
            <div class="flex justify-between items-center mb-5">
              <h2 class="text-3xl font-semibold text-black">All Releases</h2>
              <router-link to="/new-release"
                class="bg-primary text-white py-3 px-4 hover:bg-primary rounded hover:text-white focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
                <span class="flex items-center space-x-2">
                  <PlusCircleIcon size="30" /> <span>New Release</span>
                </span>
              </router-link>
            </div>

            <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">

              <router-link :to="`/release/${release.uuid}`" v-for="(release, index) in releases" :key="index"
                class="space-y-2 bg-white p-3 rounded-xl hover:bg-red transition duration-300 ease-in-out">
                <div class="w-full overflow-hidden shadow-md shadow-gray-900 h-72 bg-gray-200 rounded-xl">
                  <img :src="release.cover_image_path" :alt="release.slug" class="object-cover w-full h-full" />
                </div>

                <h4 class="font-bold text-black text-xl">
                  {{ release.title }}
                </h4>
                <MBadge :color="getBadgeColor(release.status)">{{
                  release.status
                }}</MBadge>
              </router-link>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {
  ImageIcon,
  MusicIcon,
  PlusCircleIcon,
  DownloadIcon,
  CreditCardIcon,
} from "vue-feather-icons";
import SectionLoading from "@/components/SectionLoading";
import MBadge from "@/components/MBadge";
import UpgradePlanWidget from "@/components/UpgradePlanWidget";

export default {
  components: {
    ImageIcon,
    MusicIcon,
    PlusCircleIcon,
    SectionLoading,
    DownloadIcon,
    CreditCardIcon,
    MBadge,
    UpgradePlanWidget
  },
  data() {
    return {
      releases: [],
      loading: false,
      statistics: {},
    };
  },
  computed: {
    user() {
      return this.$store.getters.user || {};
    },
  },
  mounted() {
    this.getReleases();
  },
  methods: {
    getReleases() {
      let fetch = {
        path: `/artist/releases`,
      };
      this.loading = true;

      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          const { data } = resp.data;

          this.releases = data?.releases;
          this.statistics = data;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            this.$vs.notify({
              title: "Error",
              text: err.response.data.message,
              color: "warning",
              icon: "error",
              position: "bottom-center",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Something went wrong",
              color: "dark",
              icon: "error",
              position: "bottom-center",
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getBadgeColor(status) {
      if (status === "pending") {
        return "yellow";
      } else if (status === "delivered") {
        return "green";
      } else if (status === "rejected") {
        return "red";
      }
    },
  },
};
</script>
